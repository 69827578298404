import React from 'react'
import OurAIAgents from '../NewAgentForm/OurImpact2'
import PaymentOptions from '../NewAgentForm/PaymentOption'
import Faqs from '../NewAgentForm/Faqs'

export const Sponsor = () => {
  return (
    <div>

        <OurAIAgents/>
        <PaymentOptions/>
        <Faqs/>

    </div>
  )
}
